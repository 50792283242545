<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
      <!-- 使用方法 -->
      <div class="usage_box">
        <div class="title">畅游冈山必备的景点套票，精选大冈山多个人气设施与美食店家，一週内可以任选三个喜欢的设施，出示电子票券QR code就可以轻松入场体验。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
          ●同设施只可以使用一次，相同设施不可再入场<br>
          ●票劵使用效期为购买日起<span>90天内</span><br>
           EX：<span>7/1</span>购买票券⇒<span>票期:7/1~9/28</span><br>
          ●票券有效期限: 使用第一个设施后，7日内须使用完毕<br>
          EX：<span>7/1</span>开始使用，<span>有效期限至7/7</span><br>
          ●有效期间内可任<span>选3项设施</span><br>
          ●注意: 单次购买複数票劵时，当其中一张票劵开始用，所有票劵也将同步开通 (<span>使用效期7天</span>)<br>
          ●票券开通后，7天的使用效期无法超过票券<span>90天</span>的有效期间。<br>
           EX：票券期限<span>7/1~9/28</span>，若<span>9/27</span>开始使用，其使用效期为<span>9/27~9/28</span><br>
          ●无使用完毕不能兑现<br>
          ●请事先于以下连结确认各设施注意事项・营业时间・公休日情报
        </div>
      </div>
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>
        
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事项'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='兑换地点' && !contentItem.noColor">{{cItem}}<br></span>
                    <a :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''}]" target="_blank" :href="cItem" v-show="msg.title==='官方网站' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事项特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-html="cItem" v-for="(cItem,i) in contentItem.text" :key="i"></span>
                  </li>
                </ul>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfokayama/zh/havefun_title_sc.jpg'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      dataList: [
        {
          id: 10,
          img: require('@/assets/images/hfokayama/10_SC.png'),
          title: ['冈山城天守阁 入场劵+天守阁1楼「乌城咖啡」季节圣代'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒700-0823冈山市北区丸の内2-3-1冈山市北区丸之内2-3-1',
                '入场劵: ',
                '请将电子门票出示给冈山城天守阁售票处的工作人员兑换实体入场劵',
                '「乌城咖啡」季节圣代:',
                '请向天守阁1楼「乌城咖啡」的工作人员出示电子票劵和入场券(实体)兑换季节圣代。',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['冈山城:'],noColor:true },
                { cid: 2, text: ['https://okayama-castle.jp/home-zh/'] },
                { cid: 3, text: ['冈山城天守阁1楼「乌城咖啡」:'],noColor:true },
                { cid: 4, text: ['https://okayama-castle.jp/gather-shop-zh/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。','<br>※公休日：12/29～12/31'] },
                { cid: 2, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                { cid: 3, text: ['图片仅供参考，实品请以实物为主。'] },
            
                ]
            }
          ]
        },
        {
          id: 1,
          img: require('@/assets/images/hfokayama/01_SC.png'),
          title: ['冈山県梦二郷土美术馆 本馆 入馆券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒703-8256 冈山県冈山市中区浜2丁目1-32'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://yumeji-art-museum.com/kantaiji/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。 '] },
                { cid: 2, text: ['只有本馆可以入场。(入场少年山庄需支付额外费用)'] },
            
                ]
            }
          ]
        },
        // 
        {
          id: 2,
          img: require('@/assets/images/hfokayama/02_SC.png'),
          title: ['冈山格兰比亚大酒店「lumiere」1,000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒700-8515 冈山市北区站元町1番5'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.granvia-oka.co.jp/cn/restaurant/#lum'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可享受1000日元优惠券。 '] },
                { cid: 2, text: ['餐点供应时间：9:00~21:00(最后点餐20:30)，但是圣代供应时间为12:00~21:00(最后点餐20:30)'] },
                { cid: 3, text: ['水果圣代的水果因季节而不同。产品相片仅供参考。'] },
                { cid: 4, text: ['超过优惠券金额的差额由顾客自行负担。'] },
                { cid: 5, text: ['结帐后不可取消。'] },
                { cid: 6, text: ['有可能会因为店家客满而无法使用的状况，请见谅。'] },
                ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfokayama/03_SC.png'),
          title: ['冈山县冈电博物馆入场券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒703-8281冈山县冈山市中区东山2丁目3-33'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://okayama-kido.co.jp/okadenmuseum/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['营业时间请事先至官网确认。 '] },    
                ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfokayama/04_SC.png'),
          title: ['café Antena 1,000日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒700-0812　冈山市北区出石町1-8-23'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://okayama-parfait.com/parfait/264.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1000日元优惠券。  '] },
                { cid: 2, text: ['营业时间请事先至官网确认。 '] },
                { cid: 3, text: ['若有价差需现场支付差额。 '] },
                { cid: 4, text: ['有可能会因为店家客满而无法使用的状况，请见谅。 '] },
       
                ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfokayama/05_SC.png'),
          title: ['冈山县仓敷美观地区漫步优惠劵'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '仓敷站前观光介绍所(倉敷駅前観光案內所)','冈山县仓敷市阿知1-7-2'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://okayama.visit-town.com/visittour/cp_kurashiki-machi-2023/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品销售完毕时无替代商品。请于其他店铺设施使用。另外，各店铺设施有可能临时公休。 '] },
                { cid: 2, text: ['本点券不可与其他优惠券同时或重複使用。'] },
                { cid: 3, text: ['未使用点券无法退换现金，请见谅。'] },
                { cid: 4, text: ['本点券请自行妥善保管，如有遗失恕不负责。'] },
                { cid: 5, text: ['本点券有效期间为2023/4/1 - 2024/3/31'] },
                { cid: 6, text: ['有可能无预告增减店铺及变更商品内容。'] },
                { cid: 7, text: ['1组（10张）1人使用。'] },
                { cid: 8, text: ['每个店铺所需使用的点券张数不同。也有入馆或体验即需要使用10张的设施。此时若尚需使用其他设施，请购买2组以上。'] },
                { cid: 9, text: ['请客人前往设施前先确认各注意事项。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfokayama/06_SC.png'),
          title: ['Betty Smith Factory Outlet 1,500日圆优惠券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒711-0906 冈山县仓敷市儿岛下之町5丁目2−70 BettySmith'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://betty.co.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['可以享受1500日元优惠券。'] },
                { cid: 2, text: ['营业时间请事先至官网确认。'] },
                { cid: 3, text: ['若有价差需现场支付差额。'] },
                ]
            }
          ]
        },
        {
          id: 7,
          img: require('@/assets/images/hfokayama/07_SC.jpg'),
          title: ['牛仔裤之城儿岛 巴士1日乘车券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '【儿岛营业所】〒711-0913　仓敷市儿岛味野3-2-10'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['http://shimoden.net/rosen/kikaku/jeans.html#oneday'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['兑换1日乘车券后，无法退款。即使遗失或被盗也无法补发。'] },
                { cid: 2, text: ['因自然灾害、地震等原因导致巴士延误或旅游设施关闭，也无法退款。'] },
                { cid: 3, text: ['如果错过巴士而乘坐出租车等情况，也无法退款。'] },
                { cid: 4, text: ['适用路线为 Jeans Bus、「FUREAI号」、「TOKOHAI号」、「王子岳线(至王子岳登山口站)」。'] },
                { cid: 5, text: ['必须先在儿岛营业所兑换1日乘车券后才能使用。 ※无法在巴士上兑换。'] },
                ]
            }
          ]
        },
        {
          id: 8,
          img: require('@/assets/images/hfokayama/08_SC.jpg'),
          title: ['WASHU BLUE RESORT 风笼 温泉泡汤一日券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒711-0926 冈山县仓敷市下津井吹上303-17'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['http://www.kasago.jp.c.aah.hp.transer.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['泡汤一日游有休息日，详情请参考官网。'] },
                ]
            }
          ]
        },
        {
          id: 9,
          img: require('@/assets/images/hfokayama/09_SC.png'),
          title: ['JR冈山站前家电量贩店「Bic Camera 冈山站前店」1000日圆礼券'],
          message: [
            {
              msgId: 1,
              title: '兑换地点',
              content: [{ cid: 1, text: [
                '〒700-0023 冈山县冈山市北区駅前町1-1-1 1楼综合服务臺'],noColor:true }]
            },
            {
              msgId: 2,
              title: '官方网站',
              content: [
                { cid: 1, text: ['https://www.biccamera.com.c.lj.hpcn.transer-cn.com/bc/c/super/okaimono/oshiharai/coupon/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事项',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['不能兑换成现金。'] },
                { cid: 2, text: ['对于盗窃、遗失或损毁，本公司不承担任何责任。'] },
                { cid: 3, text: ['不可兑换其他的商品券、明信片、印花、香烟、其他本公司指定的商品等。'] },
                { cid: 4, text: ['各店铺的营业时间请事先在各官方网站确认。'] },
                { cid: 5, text: ['若有价差需现场支付差额。'] },
                ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFOkayama&l=sc')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-okayama-1-week-free-pass/sc', '_self')
  }
}
</script>
<style lang="scss" scoped>
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
  // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
a:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>